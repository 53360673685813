.Link {
    height: 34px;
    margin-bottom: 7px;
    
    color: #0066ee;
    font-size: 14px;
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;  // padding makes clicking this link easier with a finger on a mobile touchscreen

    cursor: pointer;
      
}

.Link:hover {
    color: black
}
