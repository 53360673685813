$TopNavMobile-hamburgerWidth:   30px;    // width of the hamburger lines
$TopNavMobile-hamburgerHeight:  2px;     // height of each of the 3 hamburger line
$TopNavMobile-hamburgerSpacing: 6px;     // spacing separating each of the 3 hamburger lines
$TopNavMobile-hamburgerRadius:  2px;     // border radius of each line
$TopNavMobile-hamburgerTransition: 50ms; // time it takes to transition from a hamburger to an X, and vice-versa

.TopNavMobile {
  width: 100%;
  // bottom border full width of the screen
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d3d6dc;
  transform: translate( 0, 20px );
  background: #ffffff;

  .TopNavMobile-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    @media ( max-width: 465px ) {
      // this fixes the right margin on tiny browsers, which was pushing the hamburger menu too far from the right. It looked weird.
      margin-right: 5px;
    }

  }

  .TopNavMobile-logo {
    width: 194px;
    height: 45px;
    background-image: url("/img/logo_under_armour.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    margin-left: 15px;
  }

  // BEGIN hamburger code
  .TopNavMobile-hamburger {
    position: relative;
    display: inline-block;
    width: $TopNavMobile-hamburgerWidth + 20px;  // create padding left and right of the hamburger lines, so it's still clickable for some spacing around the hamburger lines
    height: 45px;
    padding-left: 10px;
    cursor: pointer;

    .TopNavMobile-hamburgerLine {
      transition: all $TopNavMobile-hamburgerTransition;
      display: block;
      top: 50%;
      margin-top: calc($TopNavMobile-hamburgerHeight / -2);
      &, &::before, &::after {
        position: absolute;
        width: $TopNavMobile-hamburgerWidth;
        height: $TopNavMobile-hamburgerHeight;
        background-color: #000000;
        border-radius: $TopNavMobile-hamburgerRadius;
      }
      &::before, &::after {
        content: "";
        display: block;
      }
      &::before {
        top: ( $TopNavMobile-hamburgerSpacing + $TopNavMobile-hamburgerHeight ) * -1;
      }
      &::after {
        bottom: ( $TopNavMobile-hamburgerSpacing + $TopNavMobile-hamburgerHeight ) * -1;
      }
    }
    &.is-active {
      // turns the 3 lines into an X
      .TopNavMobile-hamburgerLine {
        transform: rotate( 45deg );
        &::before {
          top: 0;
          opacity: 0;  // get rid of the top line
        }
        &::after {
          bottom: 0;
          transform: rotate( -90deg );
        }
      }
    }
  }
  // END hamburger code

}

.TopNavMobile-menuContainer {
  position: relative;
  // also fixed position, because it's within the TopNavMobile div
  top: 0;   // takes up zero space in the dom
  height: 0;  // height is overwritten by the style tag in react, to extend full screen height for the opacity

  .TopNavMobile-menu {
    position: absolute;
    top: 1px;
    .TopNavMobile-menuItem {
      display: block;
      cursor: pointer;
      // list-style: none;
      height: 60px;
      line-height: 65px;
      padding-left: 10px;
      padding-right: 10px;
      // float: none;
      width: 100%;
      text-align: left;
      box-shadow: inset 0 -1px 0 #d3d6dc;
      // background-color: rgba( 255, 255, 255, 0.9 );
      background-color: #f0f2f4;  // changing this to a real background color instead of a white with 10% see-through, because it was just too ugly. If you really liked it that way, I say.. really?? OK. Just put it back by uncommenting the background-color above.
      font-weight: 500;
      span {
        color: #222628;
      }

      &:hover {
        // background-color: #f0f2f4;
        span {
          color: #0066EE;
        }
      }

      &.is-active {
        span {
          // box-shadow: #0066EE 0px -4px 0px inset;
          color: #0066EE;
        }
      }

    }
    .TopNavMobile-menuFiller {
      background-color: rgba( 255, 255, 255, 0.9 );
    }
  }
}
