@font-face {
  font-family: 'Armour';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/eot/ARMOUR_W_Rg.eot');
  src: url('/fonts/eot/ARMOUR_W_Rg.eot?#iefix') format('embedded-opentype'),
       url('/fonts/woff/ARMOUR_W_Rg.woff') format('woff'),
       url('/fonts/ttf/ARMOUR_W_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Armour';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/eot/ARMOUR_W_Md.eot');
  src: url('/fonts/eot/ARMOUR_W_Md.eot?#iefix') format('embedded-opentype'),
       url('/fonts/woff/ARMOUR_W_Md.woff') format('woff'),
       url('/fonts/ttf/ARMOUR_W_Md.ttf') format('truetype');
}

@font-face {
  font-family: 'Armour';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/eot/ARMOUR_W_Bd.eot');
  src: url('/fonts/eot/ARMOUR_W_Bd.eot?#iefix') format('embedded-opentype'),
       url('/fonts/woff/ARMOUR_W_Bd.woff') format('woff'),
       url('/fonts/ttf/ARMOUR_W_Bd.ttf') format('truetype');
}
