*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Armour', Helvetica, sans-serif;
  color: #222628;
}
a {
  color: #0066EE;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
  color: #0045AC;
}
h1 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}
h2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}
h3 {
  font-size: 16px;
  font-weight: 400;
}
p {
  font-size: 16px;
  line-height: 24px;
}
ul {
  padding: 0;
}
ul li {
  list-style: none;
}

#app {
  height: 100%
}
#app .app-container {
  min-height: 100%;
}
#app .app-container {
  margin-bottom: -30px;
}
#app .app-container:after {
  content: "";
  display: block;
  height: 30px;
  clear: both;
}
#app .content-below-navbar {
  padding-top: 100px;
}
#app .content-without-navbar {
  padding-top: 40px;
  overflow: hidden;
}
#app .page-wrapper {
  max-width: 1250px;
  margin: 0 auto
}

a {
  white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

@media (max-width: 768px) {
  #app .content-below-navbar {
    padding-top: 70px;
  }
}
