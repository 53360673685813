.Error {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 80px;
  margin-bottom: 20px;
}
.Error-content {
  width: 300px;
}
.Error-logo {
  transition: width 200ms, height 200ms;
  width: 300px;
  height: 60px;
  background-image: url("/img/logo_under_armour.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 30px;
}
.Error-message {
  text-align:center;
}
