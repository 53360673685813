// both TopNavMobile and TopNavDesktop share this css
.TopNav {
  transition: transform 200ms;

  position: fixed;
  top: 40px;
  width: 100%;
  z-index: 6000;
  background: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

}
