// Account for the non-existant top nav
.Checkout {
  margin-top: -100px;
}

@media (max-width: 768px) {
  .Checkout {
    margin-top: -76px;
  }
}

.Checkout-errorContainer {
  padding: 50px 15px 50px;
  text-align: center;
}

.Checkout-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 50px 15px 50px;
  width: 90%;

  & > div {

    padding-right: 10%;
    max-width: 65%;

    &:last-child {
      padding-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .Checkout-container {
    flex-direction: column-reverse;
    padding: 15px;

    & > div {
      padding-right: 0;
      max-width: 100%;
    }
  }
}

.CheckoutTest-container,
.CheckoutSuccess-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 15px 50px;
}

.CheckoutTest-h2,
.CheckoutSuccess-h2 {
  font-weight: bold;
  margin-bottom: 33px;
}

.CheckoutTest-form {
  .InputField {
    margin-bottom: 17px;
  }
}

.CheckoutTestForm-flexWrapper {
  display: flex;
  flex-wrap: nowrap;
}

.CheckoutTestForm-flexItem {
  flex: 0 1 auto;
}

.CheckoutTestForm-orSpacer {
  margin-top: 22px;
  text-align: center;
  width: 50px;
}
