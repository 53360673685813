.Tile {
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  box-shadow: 0 20px 31px 0 rgba(4, 4, 4, 0.14);
  background-color: #ffffff;
  margin: 0 14px 36px 14px;
  .Tile-content {
    width: 100%;
    align-self: stretch;
  }
  .Tile-footer {
    text-align: center;
    width: 100%;
  }
  &.is-loading {
    &:after {
      position:absolute;
      font-size: 32px;
      font-weight: 600;
      content:"";
      top:0;
      bottom:0;
      left:0;
      right:0;
    }
  }
}