/* main nav */
.back_to_top {
  position: fixed;
  right: 0;
  bottom: 40px;
  border: 1px solid #e2e2e2;
  border-right: 0 none;
  background: #ffffff;
  padding: 5px;
}
.hidden { display:none; }
.navbar {
  height: 60px;
}
.navbar-content {
  max-width: 1250px;
  padding: 0 35px;
  margin: 0 auto;
}
.navbar-content:before {
  content: "";
  height: 0;
  display: block;
  clear: both;
}
.navbar .ua-full-logo {
  float: left;
  cursor: pointer;
  width: 300px;
  height: 20px;
  margin: 20px 40px 20px 0;
}
.navbar .menu-icon {
  display: none;
  cursor: pointer;
  width: 18px;
  height: 14px;
  float: right;
  margin: 15px 10px;
}
.navbar .nav {
  display: block;
  text-align: center;
  margin: 0;
}
.navbar .nav li {
  color: #222628;
  font-weight: 500;
  cursor: pointer;
  float: left;
  list-style: none;
  width: 190px;
  height: 59px;
  line-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  line-height: 21px;
}
.navbar .nav li:hover {
  color: #dc001c;
  box-shadow: inset 0 -4px 0 #dc001c
}

/* sub nav */
.subnav {
  position: fixed;
  overflow: auto;
  padding: 30px 15px 160px 35px;
  max-width: 310px;
  max-height: 100%;
  margin-bottom: 40px;
}
.subnav ul {
  padding: 0 15px;
  margin-top: 0;
}
.subnav ul li {
  line-height: 21px;
  margin-bottom: 15px;
  list-style: none;
}

@media (max-width: 1050px) {
  .navbar-content {
    padding: 0 10px;
  }
  .navbar .ua-full-logo {
    width: 194px;
    height: 13px;
    margin: 24px 40px 24px 0;
  }
  .navbar .nav li {
    font-size: 14px;
    width: auto;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  /* main nav */
  .navbar .ua-full-logo {
    width: 194px;
    height: 13px;
    margin: 24px 10px;
  }
  .navbar .menu-icon {
    display: block;
  }
  .navbar .mobile-nav {
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
  }
  .navbar .nav {
    display: none;
  }
  .navbar .mobile-nav li {
    color: #222628;
    font-weight: 500;
    cursor: pointer;
    float: left;
    list-style: none;
    height: 60px;
    line-height: 65px;
    padding: 0 10px;
    float: none;
    width: 100%;
    text-align: left;
    box-shadow: inset 0 -1px 0 #d3d6dc !important;
  }
  .navbar .mobile-nav li:hover {
    color: #dc001c;
    background-color: #f0f2f4;
    box-shadow: inset 0 -1px 0 #d3d6dc;
  }
  .header {
    height: 25px;
  }
  .navbar {
    height: 45px;
  }
  .navbar .ua-full-logo {
    width: 194px;
    height: 13px;
    margin: 16px 10px;
  }

  /* sub nav */
  .subnav {
    padding: 0;
    position: static;
    max-width: 100%;
  }
  .subnav .subnav-header {
    background: #f0f2f4;
    display: block;
    color: #222628;
    margin: 0;
    padding: 10px 20px;
  }
  .subnav ul {
    padding: 0 20px;
  }
}

@media (max-width: 420px) {
  .subnav {
    padding: 0;
  }
  .subnav ul li {
    font-size: 14px;
  }
}
