.OAuthRegistration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.OAuthRegistration-container {
  margin-left: 15px;
  margin-right: 15px;
}

.OAuthRegistration-h2 {
  font-weight: bold;
  margin-bottom: 33px;
}

.OAuthRegistration-form {
  .InputField {
    margin-bottom: 17px;
  }
}
