.Footer {
  background-color: #222628;
}

.Footer-flexContainer {
  // flexbox begin
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  // end flexbox
  min-height: 30px;  // the height of the black bar, increase to make the black bar taller
  // this centers the entire flexbox:
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  // end center flexbox
  @media ( max-width: 570px ) {
    // if the screen width is small, the items may be a vertical list. If so, change the list to be centered rather than spaced sparart horizontally
    justify-content: center;
  }
}
.Footer-flexItemCopyright {
  color: #ffffff;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.Footer-linksFlexbox {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  .Footer-link {
    color: #ffffff;
    font-size: 12px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    a {
      color: #ffffff;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .Footer-cookiePreferences {
      background-color: transparent;
      border: 0px solid black;
      outline: 0px solid black;
    }
  }

}
