.ResetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
  .ResetPassword-container {
    background: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
  }
  .ResetPassword-input {
    margin-bottom: 20px;
  }
  .ResetPassword-recaptcha {
    margin-bottom: 20px;
  }
  .ResetPassword-message {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .ResetPassword-minCharMessage {
    margin-bottom: 10px;
    font-size: 14px;
  }

}


