.CardSwipeForm {
  flex: 1 0 auto;
}

.CardSwipeForm-h2 {
  font-weight: bold;
  margin-bottom: 33px;
}

.CardSwipeForm-elementWrapper {
  position: relative;

  label {
    color: #9e9e9e;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(14px, 21px);
    transition: font-size 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &.CardSwipeForm-elementWrapper--focus label,
  &.CardSwipeForm-elementWrapper--hasValue label,
  :not(.StripeElement--empty).StripeElement--invalid + label,
  .StripeElement--focus + label,
  .StripeElement--complete + label {
    font-size: 12px;
    transform: translate(14px, 10px)
  }
}

.CardSwipeForm-cardholderName,
.CardSwipeForm-cardNumber,
.CardSwipeForm-expiry,
.CardSwipeForm-cvc,
.CardSwipeForm-postalCode {
  border: 2px solid #eeeeee;
  border-radius: 4px;
  cursor: text;
  height: 64px;
  margin-bottom: 31px;
  padding: 28px 12px 0;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:disabled {
    background-color: #ffffff;
  }
}

.CardSwipeForm-cardholderName {
  font-family: 'Armour', Helvetica, sans-serif;
  padding-top: 15px;
  width: 100%;
}

.CardSwipeForm-cardholderName--invalid,
.StripeElement--invalid {
  background-color: rgba(242, 38, 19, 0.02);
  border-color: #f22613;
}

.CardSwipeForm-elementsFlexContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  & > div {
    flex: 1 0 auto;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .CardSwipeForm-elementsFlexContainer {

    flex-wrap: wrap;

    & > div {
      flex: 0 1 45%;
      margin-right: 0;

      &:last-child {
        flex: 0 1 100%;
      }
    }
  }
}

.ErrorSummary {
  background-color: #ffebeb;
  border: 1px solid rgba(242, 38, 19, 0.5);
  border-radius: 4px;
  color: #ff0000;
  margin-bottom: 31px;
  max-width: 353px;
  width: 100%;
}

@media (max-width: 768px) {
  .ErrorSummary {
    max-width: 100%;
  }
}

.ErrorSummary-icon {
  border: 1.5px solid #ff0000;
  border-radius: 13px;
  float: left;
  font-size: 13px;
  font-weight: normal;
  height: 24px;
  margin: 22px 0 0 12px;
  padding-top: 2px;
  text-align: center;
  width: 24px;
}

.ErrorSummary-heading {
  font-size: 14px;
  font-weight: bold;
}

.ErrorSummary-content {
  font-size: 14px;
  margin: 17px 17px 17px 50px;

  ul {
    margin-top: 5px;
    padding: 0 10px;
  }

  ul li {
    list-style: disc;
  }
}

.CardSwipeForm-submitButton {
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'Armour', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 56px;
  margin-bottom: 20px;
  max-width: 353px;
  position: relative;
  width: 100%;
  z-index: 100;

  &.CardSwipeForm-submitButton--MMF {
    background-color: #0176c0;
  }

  &.CardSwipeForm-submitButton--MFP {
    background-color: #0063F7;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      background-color: #0053D0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    }

    &:active {
      background-color: #004EC5;
      color: #8ABDFA;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    }
  }
}

@media (max-width: 768px) {
  .CardSwipeForm-submitButton {
    max-width: 100%;
  }
}

.TermsAndConditions {
  p {
    color: #979797;
    font-size: 13px;
    line-height: 20px;
  }

  a {
    color: #4a90e2;
  }
}

.TranslucentScreenOverlay {
  background-color: #ffffff;
  height: 100vh;
  left: 0;
  opacity: .6;
  position: absolute;
  top: 0;
  width: 100vw;
}
