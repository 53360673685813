.VerifyEmail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  flex-direction: column;
  &.VerifyEmail--uaTheme {
    background: #d3d5d8;
  }
  &.VerifyEmail--mfpTheme {
    background: #F0F2F4;
    .VerifyEmail-logo {
      background-image: url('/img/mfp.svg');
    }
    .VerifyEmail-button {
      background: #0070BF
    }
  }
  &.VerifyEmail--mmfTheme {
    background: #F0F2F4;
    .VerifyEmail-logo {
      background-image: url('/img/mmf.svg');
    }
    .VerifyEmail-button {
      background: #0E4C8B;
    }
  }
  .VerifyEmail-container {
    max-width: 768px;
    background: #ffffff;
    padding: 30px 60px;
    text-align:center;
    border-radius: 10px;
  }
  .VerifyEmail-header {
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
  }
  .VerifyEmail-message {
    margin-bottom: 20px;
    color: #666671;
    font-size: 16px;
  }
  .VerifyEmail-button {
    background: #eb212e;
    border: none;
    color: #fff;
    padding: 12px 35px;
    border-radius: 3px;
    clear: both;
    display: block;
    margin: 25px auto 0;
  }
  .VerifyEmail-logos {
    margin-bottom: 12px;
  }
  .VerifyEmail-logo {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 80px;
    height: 80px;
    margin: 0 8px;
    background-image: url('/img/logo_UA_black.svg');
  }
}