.footer {
  padding: 0 35px;
}
.footer:after {
  content: "";
  display: block;
  clear: both;
}
.footer-copyright {
  float: left;
}
.footer-nav {
  float: right;
}
.footer-nav-link {
  cursor: pointer;
  margin-left: 40px
}

@media (max-width: 420px) {
  .footer {
    padding: 0;
  }
  .footer-copyright,
  .footer-nav {
    float: none;
    text-align: center;
  }
  .footer-nav-link {
    display: inline-block;
    width: 33.33%;
    margin: 0;
  }
}