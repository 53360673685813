.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
  .Login-container {
    background: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
  }
  .Login-info {
    font-size: 14px;
    line-height: 20px;
  }
  .Login-form {

  }
  .Login-input:nth-child(1) {
    margin-bottom: 17px;
  }
  .Login-input:nth-child(2) {
    margin-bottom: 10px;
  }

  .Login-forgot {
    height: 34px;
    margin-bottom: 7px;
    a {
      color: #0066ee;
      font-size: 16px;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;  // padding makes clicking this link easier with a finger on a mobile touchscreen
    }
  }

  .Login-button {
    button {
      display: inline-block;
      background-color: #222627;
      border-radius: 4px;
      color: #ffffff;
      height: 42px;
      border: 0px solid black;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      &.loginButtonDisabled {
        background-color: #22262780 !important;
      }
    }
  }
  .Login-button-facebook {
    button {
      display: inline-block;
      position: relative;
      background-color: #4267b2;
      border-radius: 4px;
      color: #ffffff;
      height: 42px;
      border: 0px solid black;
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 3px;
    }
    button:disabled, button[disabled] {
      background-color: #22262780 !important;
    }
    img {
      position: absolute;
      left: 9px;
      top:  12px;
    }
  }
  .Login-button-apple {
    margin-bottom: 15px;
    button {
      display: inline-block;
      position: relative;
      background-color: #000000;
      border-radius: 4px;
      color: #ffffff;
      height: 44px;
      border: 0px solid black;
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 3px;
    }
    img {
      position: absolute;
      left: 9px;
      top:  10px;
    }
  }

  .Login-button-or {
    color: #cac9cc;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #cac9cc;
    line-height: 0.1em;
    margin-top:    25px;
    margin-bottom: 25px;

    span {
      background-color: #ffffff;
      padding: 0 10px;
    }
  }

  .Login-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Login-message {

  }
  .Login-well {
    border: solid 1px #999999;
    border-radius: 2px;
    background-color: #d3d5d8;
    color: #000000;
    padding: 5px;
  }
  .Login-facebook {
    display: flex;
    justify-content: center;
    margin-bottom: 15px
  }
  .Login-bottomPadding {
    height: 15px;
    font-size: 1px;
  }
  .Login-facebookButton {
    color: #ffffff;
    background: #4267B2;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  .Login-help {
    font-size: 14px;
  }
}
