.ForgotPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;

  .ForgotPassword-container {
    background: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
  }
  .ForgotPassword-form {

  }
  .ForgotPassword-input {
    label {
      display: block;
    }
    input {
      display: block;
      width: 100%;
    }
  }
  .ForgotPassword-button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ForgotPassword-message {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ForgotPassword-bold {
    font-weight: bold;
  }
}
