@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

.Unsubscribe {
  width: 100%;
  background-color: rgba(227, 227, 227, 0.36);
  .Unsubscribe-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 20px 40px;
    padding-top: 30px;
    transition: padding-top 300ms;
    &.is-open {
      padding-top: 105px;
    }
    a {
      cursor: pointer;
    }
  }
}

.Unsubscribe-ListHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;

  opacity: 1;
  transition: opacity 150ms;

  &.Unsubscribe-ListHeader--mobileOnly {
    display: flex;
    justify-content: flex-end;
  }

  &.is-disabled {
    opacity: 0.36;
    &:after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      top:0;
      bottom:0;
      left:0;
      right:0;
    }
  }
}

.Unsubscribe-ListHeader-check, .Unsubscribe-ListHeader-button {
  padding: 10px 20px;
}
.Unsubscribe-ListHeader-check, .Unsubscribe-ListHeader-checkbox {
  cursor: pointer;
}

.Unsubscribe-ListHeader-checkbox {
  margin: 0 10px 0 0;
}

.Unsubscribe-ListHeader-button {
  .Button {
    padding: 12px 36px;
    font-size: 12px;
  }
}

.Unsubscribe-List {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  width: 100%;

  opacity: 1;
  transition: opacity 150ms;

  &.is-disabled {
    opacity: 0.36;
    &:after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      top:0;
      bottom:0;
      left:0;
      right:0;
    }
  }
}

.Unsubscribe-ListItem:first-child {
  border-top: solid 0.9px #d9d9d9;
}
.Unsubscribe-ListItem--clickable, .Unsubscribe-ListItem-checkbox {
  cursor: pointer;
}
.Unsubscribe-ListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  width: 100%;
  min-height: 105px;
  backdrop-filter: blur(27px);
  box-shadow: 0 20px 31px 0 rgba(51, 51, 51, 0.14);
  border-bottom: solid 0.9px #d9d9d9;
  border-left: solid 0.9px #d9d9d9;
  border-right: solid 0.9px #d9d9d9;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px 0;
  &.Unsubscribe-ListItem--header {
    background-color: #ededed;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    min-height: 0px;
    padding: 15px 25px;
  }
  &.Unsubscribe-ListItem--top {
    background-color: transparent;
    font-size: 14px;
    min-height: 0px;
    padding: 15px 25px;
  }
}

.Unsubscribe-ListItem-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  width: 100%;
}

.Unsubscribe-ListItem > div:first-child {
  padding: 0 10px 0 20px;
}

.Unsubscribe-ListItem > div:last-child {
  padding: 0 20px 0 10px;
}

.Unsubscribe-ListItem-check, .Unsubscribe-ListItem-brand,
.Unsubscribe-ListItem-description, .Unsubscribe-ListItem-link {
  align-items: center;
  font-size: 14px;
}

.Unsubscribe-ListItem-check {
  padding: 0 20px;
}

.Unsubscribe-ListItem-brand {
  min-width: 260px;
}

.Unsubscribe-ListItem-description {
  width: 100%;
  padding: 10px 20px 10px 0;
}

.Unsubscribe-ListItem-link {
  text-align: center;
  a, span {
    min-width: 220px;
    display: inline-block;
    color: #3173b6;
    white-space: nowrap;

  }
}

.Unsubscribe-ListItem-banner {
  display: inline-block;
  &.Unsubscribe-ListItem-banner--uaComLogo,
  &.Unsubscribe-ListItem-banner--mmfLogo,
  &.Unsubscribe-ListItem-banner--myfitnesspalLogo,
  &.Unsubscribe-ListItem-banner--endomondoLogo,
  &.Unsubscribe-ListItem-banner--Text {
    height: 20px;
    min-height: 12px;
    width: 100%;
    background-size: auto 90%;
    background-position: left center;
    background-repeat: no-repeat;
  }
  &.Unsubscribe-ListItem-banner--Text {
    h2 {
      margin: 0;
    }
  }
  &.Unsubscribe-ListItem-banner--uaComLogo {
    background-image: url('/img/logo_under_armour.svg');
    height: 16px;
  }
  &.Unsubscribe-ListItem-banner--recordLogo {
    background-image: url('/img/logo_record.svg');
    height: 17px;
  }
  &.Unsubscribe-ListItem-banner--mmfLogo {
    background-image: url('/img/logo_mapmyfitness.svg');
  }
  &.Unsubscribe-ListItem-banner--myfitnesspalLogo {
    background-image: url('/img/myfitnesspal.svg');
  }
  &.Unsubscribe-ListItem-banner--endomondoLogo {
    background-image: url('/img/ua_endomondo.svg');
    height: 18px;
  }
}

.Unsubscribe-heading, .Unsubscribe-form {
  margin-bottom: 28px;
  max-width: 768px;
  h1 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.18;
  }
  a {
    color: #4267b2;
    text-decoration: underline;
  }
}

.Unsubscribe-subheading {
  position: relative;
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 500;
  a {
    text-decoration: underline;
  }
}

.Unsubscribe-inputHeader {
  font-size: 16px;
  font-weight: 500;
}

.Unsubscribe-inputContainer {
  position:relative;
  width: 100%;
  min-height: 58px;
  display: block;
  padding: 8px 16px;
  opacity: 0.7;
  border-radius: 3px;
  border: solid 2px;
  border-color: #e5e6ea;
  background-color: #ffffff;
  box-shadow: none;
  transition: border-color 150ms, box-shadow 150ms;
  &.is-invalid {
    border-color: rgba(220,0,28,0.45);
    box-shadow: 0px 0px 3px 1px rgba(220,0,28,0.45);
  }
  &.is-focus {
    label {
      font-size: 10px;
      opacity: 1;
    }
  }
  label {
    position:absolute;
    display: block;
    font-size: 26px;
    z-index: 100;
    transition: font-size 150ms, opacity 150ms;
    opacity: 0.3;
  }
  input {
    position: absolute;
    display: block;
    border: none;
    background: none;
    font-size: 16px;
    width: calc(100% - 32px);
    min-height: 36px;
    padding: 16px 0 0 0;
    outline: none;
    z-index: 200;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
    }
  }
}

.Unsubscribe-message {
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 300ms, max-height 300ms, margin-top 300ms;
  width: 100%;
  height: 0;
  max-height: 0;
  overflow: hidden;
  text-align:center;
  border-width: 0;
  margin-top: -2px;
  z-index: 100;
  &.is-open {
    margin-top: 1px;
    height: 75px;
    max-height: 75px;
  }
  &.is-success {
    border: solid 1px #4ee498;
    background-color: #e7fbf2;
  }
  &.is-failure {
    background-color: #dc001c;
  }
}

.Unsubscribe-successIcon {
  display: inline-block;
  width: 32px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/img/success.svg');
}

@media (min-width: 480px) {

}

@media (min-width: 992px) {
  .Unsubscribe-ListItem-content {
    flex-direction: row;
  }
  .Unsubscribe-ListHeader-check, .Unsubscribe-ListHeader-button {
    padding: 20px 25px;
  }
  .Unsubscribe-ListHeader-checkbox {
    margin: 0 20px 0 0;
  }
  .Unsubscribe-ListItem > div:first-child {
    padding: 0 20px 0 25px;
  }
  .Unsubscribe-ListItem > div:last-child {
    padding: 0 25px 0 20px;
  }
  .Unsubscribe-ListItem-description {
    padding: 0 20px;
  }
  .Unsubscribe-ListItem-link {
    a {
      padding: 0 20px;
    }
  }
  .Unsubscribe-ListHeader {
    &.Unsubscribe-ListHeader--mobileOnly {
      display: none;
    }
  }
}
