[class*='col-'] {
  float: left;
  min-height: 1px;
  padding-right: 20px;
}

.grid {
  width: 100%;
  max-width: 1250px;
  min-width: 748px;
  margin: 0 auto;
  overflow: hidden;
}

.grid:after {
  content: "";
  display: table;
  clear: both;
}

.col-1-1 {
  width: 100%;
}
.col-2-3, .col-8-12 {
  width: 66.66%;
}

.col-1-2, .col-6-12 {
  width: 50%;
}

.col-1-3, .col-4-12 {
  width: 33.33%;
}

.col-1-4, .col-3-12 {
  width: 25%;
}

.col-1-5 {
  width: 20%;
}

.col-1-6, .col-2-12 {
  width: 16.667%;
}

.col-1-7 {
  width: 14.28%;
}

.col-1-8 {
  width: 12.5%;
}

.col-1-9 {
  width: 11.1%;
}

.col-1-10 {
  width: 10%;
}

.col-1-11 {
  width: 9.09%;
}

.col-1-12 {
  width: 8.33%
}

.col-11-12 {
  width: 91.66%
}

.col-10-12 {
  width: 83.333%;
}

.col-9-12 {
  width: 75%;
}

.col-5-12 {
  width: 41.66%;
}

.col-7-12 {
  width: 58.33%
}

.push-2-3, .push-8-12 {
  margin-left: 66.66%;
}

.push-1-2, .push-6-12 {
  margin-left: 50%;
}

.push-1-3, .push-4-12 {
  margin-left: 33.33%;
}

.push-1-4, .push-3-12 {
  margin-left: 25%;
}

.push-1-5 {
  margin-left: 20%;
}

.push-1-6, .push-2-12 {
  margin-left: 16.667%;
}

.push-1-7 {
  margin-left: 14.28%;
}

.push-1-8 {
  margin-left: 12.5%;
}

.push-1-9 {
  margin-left: 11.1%;
}

.push-1-10 {
  margin-left: 10%;
}

.push-1-11 {
  margin-left: 9.09%;
}

.push-1-12 {
  margin-left: 8.33%
}

@media handheld, only screen and (max-width: 767px) {
  .grid {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 10px;
  }

  [class*='col-'] {
    width: auto;
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
  [class*='push-'] {
    margin-left: 0;
  }
}