.ua-logo {
  width: 126px;
  height: 72px;
  background-image: url('/img/logo_UA.svg');
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.menu-icon,
.user-icon,
.preferences-icon,
.privacy-icon,
.pdf-icon,
.mmf-logo,
.record-logo,
.ua-com-logo,
.ua-full-logo,
.endomondo-logo,
.myfitnesspal-logo {
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.menu-icon { background-image: url('/img/icon_menu.svg') }
.user-icon { background-image: url('/img/icon_account_user.svg') }
.preferences-icon { background-image: url('/img/icon_preferences.svg') }
.privacy-icon { background-image: url('/img/icon_privacy.svg') }
.pdf-icon { background-image: url('/img/icon_pdf.svg') }
.mmf-logo { background-image: url('/img/logo_mapmyfitness.svg') }
.record-logo { background-image: url('/img/logo_record.svg') }
.ua-com-logo { background-image: url('/img/logo_ua_com.svg') }
.ua-full-logo { background-image: url('/img/logo_under_armour.svg') }
.endomondo-logo { background-image: url('/img/ua_endomondo.svg') }
.myfitnesspal-logo { background-image: url('/img/ua_myfitnesspal.svg') }

.mmf-logo-disabled { background-image: url('/img/logo_mapmyfitness_gray.svg') }
.ua-com-logo-disabled { background-image: url('/img/logo_ua_com_gray.svg') }
.endomondo-logo-disabled { background-image: url('/img/ua_endomondo_gray.svg') }
.myfitnesspal-logo-disabled { background-image: url('/img/ua_myfitnesspal_gray.svg') }

.ua-logo-simple-nav {
  background-position: center left;
  background-size: cover;
  display: inline-block;
  height: 37px;
  margin-top: 30px;
  width: 61px;
}

.pdf-icon {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
}
