.SideNav {
  padding-bottom: 40px;  // 30px is enough, but Android Chrome browser cuts off the last item at 30px, so I changed it to 40px
}
.SideNav-LinkList {
  padding: 0 15px;
  .SideNav-LinkList-item {
    display: block;
    .SideNav-LinkList-link {
      display: inline-block;
      position:relative;
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0;
      text-decoration: none;
      color: #222628;
      padding: 6px 10px;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &.is-active {
      > .SideNav-LinkList-link {
        font-weight: bold;
        &:after {
          position:absolute;
          content:" ";
          background: #222628;
          top:0;
          bottom:0;
          left:0;
          width:2px;
        }
      }
    }
    > .SideNav-LinkList {
      .SideNav-LinkList-item {
        .SideNav-LinkList-link {
          color: #0066EE;

          &:active, &:hover {
            color: #0045AC;
          }
        }
      }
    }
  }
}
