.OAuthTest-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 15px 50px;
}

.OAuthTest-h2 {
  font-weight: bold;
  margin-bottom: 33px;
}
