.ErrorMessage {
  // background-color: #f9e5e8;
  // border: 1px solid #cf0013;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  font-size: 1px; // when not displaying an error message, this is for the &nbsp;

  opacity: 0;
  transition: opacity 100ms ease-out;

  &.isActive {
    opacity: 1;
    min-height: 39px;
  }
  .ErrorMessage-text {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 400;
    color: #dc0019;
  }
}
