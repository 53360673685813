.BlueLink {
  height: 34px;
  margin-bottom: 7px;
  a {
    color: #0066ee;
    font-size: 13px;
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;  // padding makes clicking this link easier with a finger on a mobile touchscreen
  }
}
