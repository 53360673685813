.OrderSummary {
  flex: 0 1 auto;
}

.OrderSummary-h2 {
  font-weight: bold;
  margin-bottom: 33px;
}

.LineItem {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 14px;
  justify-content: space-between;
}

.LineItem--total {
  border-top: 1px solid #979797;
  font-weight: bold;
  padding-top: 7px;
}

.LineItem-itemName {
  flex: 0 1 auto;
  padding-right: 30px;
}

.LineItem-amount {
  flex: 0 1 auto;
}

.SubscriptionDetails {
  background-color: #f2f9fd;
  border: 1px solid #b5dcf7;
  margin-bottom: 20px;
  margin-top: 34px;
  padding: 11px 18px;
}
