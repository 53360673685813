.InputField {
  .InputField-labelContainer {
    position: relative;
    height: 0;  // this is a special trick I often use. 0-height relative, then absolute within allows placing something anywhere relative to the parent but takes up no space in the DOM
    label {
      font-size: 14px;
      position: absolute;
      left: 15px;
      top: 16px;
      color: #a0a0a0;
      cursor: text;
      transition: transform 100ms ease-out;
      transform-origin: top left;
    }
    label.isActive {
      color: #616161;
      transform:         translate(0px, -10px) scale(0.70);
      -webkit-transform: translate(0px, -10px) scale(0.70);
      -moz-transform:    translate(0px, -10px) scale(0.70);
      -o-transform:      translate(0px, -10px) scale(0.70);
      -ms-transform:     translate(0px, -10px) scale(0.70);
    }
    .endAdornmentContainer {
      position: absolute;
      top: 0;  // will be full height of the text field, so start from the very top
      right: 5px;
      cursor: pointer;

      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      // this centers the endAdornmentText vertically. This makes the full height clickable (easier to press on mobile)
    }
    .endAdornment {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 12px;
      color: #979797;
      text-transform: uppercase;  // all caps text
      user-select: none;  // this and following lines disable text highlighting if user double-clicks
      -webkit-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none;
      -o-user-select: none;
      -moz-user-select: none;
    }
  }
  input, input[type=text], input[type=password], input[type=email] {
    // safari and chrome look for different things here. Chrome works with "input", but safari wants "input[type=text]" for the exact same thing
    border-radius: 2px;
    border: 1px solid #D0D0D0;
    padding-left: 15px;
    // padding-right: 15px;
    // padding-right is handled by the react component to handle show/hide end adornment text
    color: #000000;
    &:focus, &:active {
      // outline: 2px solid #0066ee;
      border: 1px solid black;
      outline: 0px solid white;
      // box-shadow: 0 0 0px 0 rgba( 0, 102, 238, 0.25 ) !important;
    }
    &::-ms-clear {
      // remove the X clear icon that displays on the right side of text input on Internet Explorer
      display: none;
    }
  }
}
