.CheckoutTopNav {
  box-shadow: 2px 2px 4px 0 rgba(80, 80, 80, 0.15);
  height: 70px;
}

@media (max-width: 768px) {
  .CheckoutTopNav {
    height: 52px;
  }
}

.CheckoutTopNav-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
  position: relative;
}

@media (max-width: 768px) {
  .CheckoutTopNav-container {
    text-align: center;
  }
}

.CheckoutTopNav-logo--MMF {
  height: 24px;
  margin-top: 22px;
}

.CheckoutTopNav-logo--MFP {
  margin-top: 22px;
}

@media (max-width: 768px) {
  .CheckoutTopNav-logo--MMF {
    margin-top: 15px;
    max-width: 180px;
  }

  .CheckoutTopNav-logo--MFP {
    height: 28px;
    margin-top: 14px;
  }
}

.CheckoutTopNav-avatar {
  border-radius: 4px;
  position: absolute;
  height: 36px;
  right: 15px;
  top: 18px;
  width: 36px;
}

@media (max-width: 768px) {
  .CheckoutTopNav-avatar {
    right: 10px;
    top: 9px;
  }
}
