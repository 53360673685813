.CommonButton {
  button {
    display: inline-block;
    background-color: #222627;
    border-radius: 4px;
    color: #ffffff;
    height: 42px;
    border: 0px solid black;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    &.CommonButton-disabled {
      background-color: #22262780 !important;
    }
    &.CommonButton-allCaps {
      text-transform: uppercase;
    }
  }
}
