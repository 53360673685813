.Loading {
  margin-top: 25px;
  .LoadingFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
  .Loading-container {
    text-align: center;
  }
  .Loading-animation {
    animation: pulseLoading 1000ms infinite;
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  }
  .Loading-ualogo {
    width: 150px;  // enough space for the "Loading" text
  }
  .Loading-text {

  }
  @keyframes pulseLoading {
    0%   { transform: scale( 1.0 ) }
    49%  { transform: scale( 1.2 ) }
    100% { transform: scale( 1.0 ) }
  }
}
