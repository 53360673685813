.TopNavDesktop {
  width: 100%;
  // bottom border full width of the screen
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d3d6dc;

  .TopNavDesktop-container {
    max-width: 1200px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
  }

  .TopNavDesktop-logo {
    transition: transform 200ms;
    width: 25%;
    height: 60px;
    background-image: url("/img/logo_under_armour.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 30px;
    margin-left: 15px;
  }

  .TopNavDesktop-list {
    height: 60px;
    max-height: 60px;

    // begin flexbox
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;  // display all elements starting from the left to right
    align-content: center;
    align-items: stretch;

    width: 75%;
  }
  .TopNavDesktop-item {
    height: 100%;

    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #222628;
    font-weight: 500;
    font-size: 16px;
    padding: 0 15px;
    span {
      transition: transform 200ms;
    }
    &.is-active {
      box-shadow: #dc001c 0px -4px 0px inset;
      color: #dc001c;
    }
    &:hover,
    &:focus,
    &:active {
      // when user hovers over the nav item, they get a red bottom line and the text turns red
      box-shadow: #dc001c 0px -4px 0px inset;
      color: #dc001c;
    }
  }
  &.is-small {

  }
}
