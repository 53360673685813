.Button {
  display: block;
  border-radius: 2px;
  padding: 12px 16px;
  border: none;
  text-transform: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.Button--dark {
    background-color: #000000;
    text-align: center;
    color: #ffffff;
  }
}