.MarkdownContainer {
  border-left: 1px solid #d3d6dc;

  padding-left: 30px;
  // NOTE padding-left here is overridden within components/MarkdownContent/index.js when viewing the /data-management page.
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 30px;

  @media only screen and (min-width: 769px) {
    .SidePaddingOnDesktop {
      padding-left: 30px;
      padding-right: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .PaddingSection {
      padding-left: 30px;
      padding-right: 10px;
    }
  }

  h1 {
    margin-top: 0;
  }
  h2 {
    line-height: 1.25em;
    padding-top: 120px;
    margin-top: -100px;
  }
  ul {
    padding: 10px 40px;
    li {
      list-style: inherit;
    }
  }
  ul li,
  ol li {
    margin-bottom: 8px;
  }
  ul li p:first-child,
  ol li p:first-child {
    padding: 0 0 24px;
    display: inline;
  }
  table {
    border-collapse: collapse;
    width:100%;
    text-align:left;
    font-size:12px;
    border: 1px solid black;
    padding:15px;
  }

  th {
    width:20%;
    height:25px;
    background-color:LightGray;
    border: 1px solid black;
    padding:10px;
    text-align: center
  }

  td {
    border:1px solid black;
    padding:10px;
    vertical-align: text-top;
  }
  .siteLinks {
    min-height: 160px;
    max-width: 1250px;
    margin: 0 auto;
    padding: 45px 0;
    text-align: center;
  }
  .siteLinks > div {
    display: inline-block;
    float: none;
    padding: 20px 27px;
    width: auto;
  }
  .siteLinks a {
    display: inline-block;
    text-indent: -99999px;
  }
  .siteLinks a.ua-com-logo {width: 112px; height: 20px}
  .siteLinks a.mmf-logo {width: 222px; height: 25px}
  .siteLinks a.myfitnesspal-logo {width: 201px; height: 24px}
  .siteLinks a.endomondo-logo {width: 204px; height: 21px}

  .anchor {
    display: block;
    content: "";
    margin-top: -125px;
    height: 125px;
    visibility: hidden;
  }
  .highlight {
    background: #f0f2f4;
    padding: 12px;
    margin: 0 -10px 0;
  }
  .notice {
    position: relative;
    background: #FFFFD2;
    padding: 12px;
    margin: 0 -10px 15px 0;
    width: 100%;
  }
  .Previously {
    font-size: 13px;
    font-style: italic;
    margin-top: -12px;
    label {
      display: block;
      margin: 10px 0;
    }
    .Previously-list {
      display: flex;
      flex-direction: row;
      @media (max-width: 666px) {
        flex-direction: column;
      }
    }
    .Previously-item {
      margin: 0 35px 25px 0;
    }
    .Previously-pdfIcon {
      display: inline-block;
      background-image: url('/img/icon_pdf.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 14px;
      height: 18px;
      margin-bottom: -3px;
    }
    a {
      display: inline-block;
      margin: 5px 0 5px 3px;
    }
  }
  li > .ReadMore.is-active {
    margin-top: 15px;
  }
  .ReadMore {
    position:relative;
    display: inline-block;
    max-width: 100%;
    &.is-active {
      margin-bottom: 45px;
    }
    > div {
      display: none;
      > .ReadMore {
        padding-left: 50px;
      }
    }
    &.is-active > div {
      display: block;
    }
    &.is-active > .ReadMore-link {
      position:absolute;
      top: calc(100% + 15px);
    }
  }


  .ExpandableFirst {
    border-top: 1px solid #d4d4d4;
  }
  .Expandable {
    @media only screen and (min-width: 769px) {
      border: 1px solid #d4d4d4;
      margin-bottom: 10px;
      border-radius: 4px;
    }
    @media only screen and (max-width: 768px) {
      border-left: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
    }

    .Expandable-link {
      position: -webkit-sticky;
      position: sticky;
      top: 70px;
      @media only screen and (min-width: 769px) {
        border-radius: 4px;
      }
      background-color: #ffffff;
    }
    .Expanded {
      max-height: 0;
      overflow: hidden;
      transition: max-height ease-out 100ms;
      padding-right: 30px;
      padding-left: 15px;
    }
    &.is-active {
      padding-bottom: 20px;
      .Expanded {
        // intentionally blank.
        // max-height is set inside the javascript function: bindReadMoreAndSetHTML
      }
      .arrow {
        img {
          transform: rotate( -180deg );
        }
      }
    }
  }

  .ClickableBox {
    // the overall box, with solid border
    display: block;
    // border: 1px solid #d4d4d4;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;

    // margin-bottom: 15px;

    .ClickableBoxFlex {
      // the overall flex inside the box, showing arrow on the right then text on the left
      display: flex;
      flex-direction: row-reverse;  // start from the right (for the arrow)
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    .arrow {
      position: relative;
      width: 12px;
      height: 9px;
      top: -7px;
      margin-left: 20px;
      img {
        transition: transform ease-out 100ms;
        width: 12px;
        height: 9px;
      }
    }

    .ClickableBoxText {
      display: flex;
      flex-direction: column;
      .Label {
        font-weight: 700;
        display: block;
      }
      .SubLabel {
        display: block;
        color: #616161;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }

  }

  .TwoColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
      padding-left: 30px;
      padding-right: 10px;
    }

    .Column {
      width: 50%;
      min-width: 300px;
      .Title {
        font-size: 20px;
      }
    }
  }
}


@media (max-width: 1200px) {
  .MarkdownContainer {
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 768px) {
  .MarkdownContainer {
    border: 0;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
}
@media (max-width: 420px) {
  .MarkdownContainer {
    font-size: 14px;
    h2:before {
      display: block;
      content: "";
      margin-top: -90px;
      height: 90px;
      visibility: hidden;
    }
    p, li {
      font-size: 14px;
    }
  }

}
