$Home-narrowWidth: 420px;

.Home {
  // BEGIN hero, the front-and-center home page section that says "account and privacy center"
  .Home-hero {
    background-size: cover;
    background-position: center center;
    background-color: #dc001c;
    background-image: url('/img/hero.png');
    text-align: center;
    width: 100%;
    height: 235px;
    padding: 50px 0;

    @media ( max-width: $Home-narrowWidth ) {
      height: 120px;
      padding: 27px 0;
    }

    h1 {
      color: #ffffff;
      text-align: center;
      font-weight: 800;
      font-size: 36px;
      line-height: 36px;  // needed for mobile. Without this, the text would look squashed on narrow browser width, when the text wraps to a second line.
      @media ( max-width: $Home-narrowWidth ) {
        font-size: 18px;
      }
    }

    .ua-logo {
      display: inline-block;
      @media ( max-width: $Home-narrowWidth ) {
        width: 56px;
        height: 33px;
      }
    }

  }
  // END hero

  // BEGIN site links
  .siteLinks {
    min-height: 160px;
    max-width:  1250px;
    margin: 0 auto;
    padding: 45px 0;
    text-align: center;
    .site {
      display: inline-block;
      float: none;
      padding: 20px 27px;
      width: auto;
      a {
        display: inline-block;
        text-indent: -99999px;
      }
    }
  }
  // END site links

  // BEGIN overview details section
  .overviewDetails {
    // flexbox
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // max-width: 1250px;
    // margin: 0 auto;
    // border-top-width: 2px;
    // border-top-style: solid;
    // border-top-color: #d3d6dc;
    // height: auto;
    .overviewDetail {
      text-align: center;
      font-size: 16px;
      color: #222628;
      padding: 35px 0;
      width: 415px;
      .overviewDetailLink {
        cursor: pointer;
        color: #0066EE;
        &:active, &:hover {
          color: #0045AC;
        }
      }
      h3 {
        font-weight: 500;
        font-size: 20px;
        color: #222628;
      }
      p {
        display: inline-block;
        max-width: 300px;
        margin: 0;
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 10px;
        }
      }
      i {
        display: inline-block;
        width:  52px;
        height: 52px;
      }
    }
  }
  // END overview details

}
