.notification-bar {
  width: 100%;
}

.notification-message {
  display: none;
  color: #fff;
  font-size: 18px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 100%;
}

.notification-message.active {
  display: block;
}

.notification-message.success {
  background-color: #5fa60d;
}
.notification-message.error {
  background-color: #dc001c;
}
