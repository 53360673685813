.TopNavMessage-container {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  position: relative;
  .TopNavMessage {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    padding-right: 40px;
  }
  .TopNavMessage-close {
    position: absolute;
    top: 15px;
    right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
